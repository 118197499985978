.carousel.carousel-slider button.control-arrow.control-prev {
    position: absolute;
    right: 70px;
    left: unset;
    top: 25px;
    border-radius: 6px;
    border: 1px solid #FFF;
    background: #000;
    height: 30px;
}

.carousel.carousel-slider .control-arrow {
    padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel.carousel-slider button.control-arrow.control-next {
    position: absolute;
    right: 25px;
    left: unset;
    top: 25px;
    border-radius: 6px;
    border: 1px solid #FFF;
    background: #000;
    height: 30px;
}

.carousel .control-dots {
    display: none;
}

.imageWrapper {
    height: 680px;
    position: relative;

}

.imageWrapper img {
    width: 100%;
    height: 100%;
}

.bannerContent {
    position: absolute;
    z-index: 9999;
    color: #ffff;
    max-width: 1536px;
    left: 0;
    right: 0;
    padding-left: 30px;
    margin: 0 auto;
    text-align: left;
    top: 50%;
    transform: translate(0, -50%);
}

.bannerContent h1 {
    color: #FFF;
    font-size: 48px;
    font-style: italic;
    font-weight: 700;
    line-height: 49.4px;
    text-transform: uppercase;
    width: 41%;
}

.bannerContent p {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    width: 41%;
    margin-top: 20px;
}

.bannerContent a {
    border-radius: 6px;
    background: #F0950C;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    width: 170px;
    margin-top: 20px;
}

.bannerContent img {
    width: 20px !important;
}

.paginations {
    margin-top: 85px;
    margin-bottom: 40px;
}
.highlights{
    padding: 40px 50px;
    border: 1px solid #2E2E2E;
    background: #262626;
    margin: 20px 35px;
    color: #FFFFFF;
    border-radius: 6px;
    height: 100%;
    width: 80%;
    position: relative;
    margin-right: 17.5px;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.65);
}
.highlights ul li .MuiPaginationItem-ellipsis,
.highlights ul li button,
.newAi ul li .MuiPaginationItem-ellipsis,
.newAi ul li button,
.paginations ul li .MuiPaginationItem-ellipsis,
.paginations ul li button {
    color: #fff;
}
.highlights ul li .Mui-selected,
.newAi ul li .Mui-selected,
.paginations ul li .Mui-selected {
    background-color: #F0950C !important;
    border-radius: unset;
    border-radius: 4px;

}

.newAi .category {
    background-color: #F0950C;
    padding: 5px 18px;
    text-decoration: none;
    color: #fff;
    border-radius: 100px;
    font-size: 15px;
}

.header-top-wrapper{
    padding-top: 88px;
}

.newAi .title {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-decoration: none;
}

.newAi .imageText {
    position: absolute;
    bottom: 10%;
    opacity: 0.7;
    background: #080808;
    margin: 0 20px;
    padding: 10px 20px;
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
}

.newAi .imagelistbox ul {
    display: flex !important;
}

.newAi .imagelistbox ul li {
    width: 33.33%;
}
.newAi .postdate,
.highlights .createdAt {
    position: relative;
    padding-left: 30px;
}
.newAi .postdate::before,
.highlights .createdAt::before {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
    top: -2px;
    background-repeat: no-repeat;
    background-size: 22px;
}

.highlights .heading {
    color: #FFF;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;
}



/* header */
header.header{
    padding: 15px 45px;
    background-color: #FFFFFF;
    z-index: 4;
    position: fixed;
}
.header .menus-wrapper {
    align-items: center;
}
.header .menus-wrapper a{
    font-size: 14px;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    padding: 0;
    line-height: normal;
    height: auto;
    display: inline-block;
}
.header .menus-wrapper a:hover{
    background:none;
}

.header .menus-wrapper a.links{
    padding: 0 20px;
}
.header .menus-wrapper a.links:hover{
    text-decoration: underline;
}
.header .menus-wrapper .social-links-wrapper{
    margin-left: 50px;
}
.header .menus-wrapper .social-links-wrapper a{
    padding: 0 15px;
}
.header .inner-wrapper{
    min-height: auto;
}

.header .logo-wrapper img{
   max-width: 170px;
}
.header .hamburger-icon{
   border: 1px solid #eee;

}
.header .hamburger-icon svg{
    fill: black;
}
/* header */

/* carousel */
.carousel-wrapper .slider-wrapper .slide{
    position: relative;
}
.carousel-wrapper .slider-wrapper .slide:after{
    content: '';
    background-color: rgb(8 8 8 / 37%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: auto;
    z-index: 0;
}

.carousel-wrapper .bannerContent{
    z-index: 1;
    max-width: 100%;
    padding: 0 45px;
}
.carousel-wrapper .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    object-fit: cover;
}
/* carousel */

/* search suggestion*/
.search-suggestion-wrapper{    
    padding: 0 156px;
}
.search-suggestion-wrapper .suggestion-wrapper p{
   text-align: center;
   overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 30px;
}
.search-suggestion-wrapper .suggestion-wrapper .grid-wrapper svg.star{
    fill: transparent;
}
.search-suggestion-wrapper .suggestion-wrapper .grid-wrapper:nth-child(1) svg.star{
    stroke: #FEC808;
}
.search-suggestion-wrapper .suggestion-wrapper .grid-wrapper:nth-child(2) svg.star{
    stroke: #FE0808;
}
.search-suggestion-wrapper .suggestion-wrapper .grid-wrapper:nth-child(3) svg.star{
    stroke: #093AE5;
}
.search-suggestion-wrapper .suggestion-wrapper .grid-wrapper:nth-child(4) svg.star{
    stroke: #22FE0F;
}
/* search suggestion*/

/* latest news*/
.latest-news{
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.65);
}
.latest-news .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    gap: 20px;
    justify-content: space-between;
}
.latest-news .grid-column {
    flex: 0 0 calc(20% - 20px); 
    max-width: calc(20% - 20px);
    box-sizing: border-box;
}
.latest-news  img{
    object-fit: cover;
}
.latest-news .created-by{
    font-size: 14px;
    color: #CFD4E4;
    text-transform: capitalize;
    font-weight: 500;
}
.latest-news .pub-date{
    color: #BAB8B8;
    font-size: 14px;
    font-weight: 500;
}
.latest-news  .created-wrapper{
   margin-top: 30px;
}
/* latest news*/

/* footer */
.footer-wrapper {
    background: black;
    padding-top: 80px;
    text-align: center;
}
.footer-wrapper .social-media{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding-bottom: 80px;
}
.footer-wrapper .social-media li{
    width: 300px;
    height: 50px;
}


.footer-wrapper form button{
    background: rgb(44, 58, 220);
    padding: 14px 12px;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
/* footer */

.result-wrapper{
    background-color: #161515;
    padding: 116px 35px 20px;
}
.result-wrapper .result-title{
    font-size: 32px;
    font-style: italic;
    font-weight: 600;
    color: white;
    margin-bottom: 20px;
}
.result-wrapper .scrollDivRef .result-list{
    margin: 0;
}
.result-wrapper .scrollDivRef .result-box a{
    color: white;
}
.result-wrapper .result-box{
    width: 100%;
    padding: 16px;
    overflow: hidden;
    border: 1px solid grey;
    background: #262626;
    margin: 0 0 40px;
    color: #FFFFFF;
}
.sub-title,h5.sub-title{
    font-size: 32px;
    font-style: italic;
    font-weight: 600;
    color: white;
    margin-bottom: 20px;
}
.video-wrapper,.sources-wrapper{
    margin-bottom: 30px;
}
.video-wrapper a{
    display: block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 22px;
}
.video-wrapper a img{
    width: 100%;
    height: 313px;
    object-fit: cover;
    max-width: 100%;
    border-radius: 6px;
}
.video-wrapper .paginations{
    margin: 5px 0;
}
.video-wrapper .video-list-wrapper{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    margin-top: 10px;
}
.video-wrapper .video-list-wrapper li{
    flex: 0 0 48%;
    max-width: 48%;
}
.video-wrapper .video-list-wrapper li:nth-child(1),.video-wrapper .video-list-wrapper li:nth-child(2){
    margin-bottom: 8px;
}
.video-wrapper iframe{
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    display: block;
}
.sources-wrapper .box-wrapper{
    background: #373737;
    border: 1px solid #FFFFFF;  
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    padding: 20px;
    height: 106px;
    align-items: center;
    margin-bottom: 20px;
}
.sources-wrapper .box-wrapper:last-child{
    margin-bottom: 0;
}
.sources-wrapper .box-wrapper img{
    max-width: 70px;
    margin-right: 20px;
}
.sources-wrapper .box-wrapper h6{
    font-size: 20px;
    font-weight: bold;
    color: white;
    line-height: inherit;
}
.related-wrapper .related-content-list{
   display: flex;
   margin-bottom: 25px;
   justify-content: space-between;
}
.related-wrapper .related-content-list:last-child{
    margin-bottom: 0px;
}
.related-wrapper .related-content img{
    width: 138px;
    height: 127px;
    object-fit: cover;
    border-radius: 5px;
    margin-left: 40px;
    min-width: 138px;
    border: 1px solid #343434;
}
.related-wrapper .content-wrapper a{
  font-size: 20px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
}
.related-wrapper .content-wrapper p{
    font-size: 16px;
    color: #BAB8B8;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 15px;
}
.related-wrapper .paginations{
    margin: 5px 0;
}
.follow-up-wrapper{
  background-color: #2D2D2D;
  border: 1px solid #BABABA;
  padding: 24px;
  border-radius: 5px;
  display: flex;
  gap: 15px;
  margin: 40px 0 30px;
  width: 100%;
}
.follow-up-wrapper input{
  background-color: #D9D9D9;
  border-radius: 5px;
}
.follow-up-wrapper button,.follow-up-wrapper button:hover{
  background-color: #F0950C;
  border-radius: 5px;
}
.follow-up-wrapper svg{
    color: black;
    font-size: 28px;
}
.follow-up-wrapper button:hover svg{
    color: white;
}
.scroll-container{
    padding-right: 10px!important;
}
.scroll-container::-webkit-scrollbar {
    width: 10px;
}  
.scroll-container::-webkit-scrollbar-track {
    background: rgb(240 240 240 / 17%);
}  
.scroll-container::-webkit-scrollbar-thumb {
    background-color: rgb(137 136 136 / 24%);;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}
.scroll-container:hover::-webkit-scrollbar-thumb {
    background-color: #555; 
}

.feeds{
    border: 1px solid #343434;
    background: #262626;
    margin: 20px 35px;
    padding: 40px 50px;
    color: #FFFFFF;
    border-radius: 6px;
    height: 100%;
    position: relative;
    margin-left: 17.5px;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.65);
}
.feeds-tab-wrapper{
    display: block;
    width: 100%;
}
.feeds .podcast-tabs {
    border-radius: 6px;
    border: 1px solid #E5E9F6;
    background: #A09A9A;
}

.feeds .podcast-tabs button {
    width: 50%;
    padding: 0;
    min-height: 0;
    padding: 16px 0px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.032px;
    max-width: 100%;
}

.feeds .podcast-tabs button span {
    display: none;
}

.feeds .podcast-tabs .Mui-selected {
    background: #F0950C;
    color: #fff;
}

.feeds .podcast-tabs .css-1aquho2-MuiTabs-indicator {
    background-color: transparent;
}

.feeds #simple-tabpanel-1 .css-19kzrtu,
.feeds #simple-tabpanel-0 .css-19kzrtu {
    padding: 24px 0;
}

.feeds .feed-image {
    width: 256px;
    height: 56px;
    object-fit: cover;
    border-radius: 5px;
}

.feeds .feed-content {
    padding: 14px;
    border-radius: 5px;
    border: 1px solid rgba(176, 176, 176, 0.10);
    position: relative;
    width: 100%;
}

.feeds .feed-content a {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-decoration: none;
}

.feeds .feed-content svg {
    border-radius: 6px;
    border: 0.5px solid rgba(255, 255, 255, 0.40);
    background: #F0950C;
}

.feeds .feed-date {
    color: #BAB8B8;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 32px;
}

.pagination-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.newAi { 
    border:1px solid #343434;
    background: #262626;
    margin: 35px 35px 20px;
    padding: 40px 50px;
    color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.65);
}
.newAi .news-list-wrapper .news-list:nth-child(1) .category{
   background-color: #0085FF;
}
.newAi .news-list-wrapper .news-list:nth-child(2) .category{
    background-color: #F0950C;
 }
 .newAi .news-list-wrapper .news-list:nth-child(3) .category{
    background-color: #EB00E1;
 }
 .newAi .news-list-wrapper .news-list:nth-child(4) .category{
    background-color: #00DDEB;
 }
 .newAi .news-list-wrapper .news-list:nth-child(5) .category{
    background-color: #2f7e66;
 }
 .newAi .news-list-wrapper .news-list .content-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
.highlight-card img {
    width: 150px;
    height: 156px;
    object-fit: cover;
    border-radius: 5px;
    padding: 5px;
}
.articleImage {
    max-width: 100%;
    margin-right: 10px;
    border-radius: 6px;
    height: 100%;
}
.highlight-card {
    display: flex;
    flex-direction: row;
}
.podcast-card {
    display: flex;
    flex-direction: row;
}

.mobile-input-wrapper{
    display: none;
}

.skeleton-loader-wrapper{
   margin-top:0px;
}
.skeleton-loader-wrapper .loader-line{
    background-color: rgb(255 255 255 / 11%);
}
.photos-box-wrapper .main-photo{
    height: 582px;
    object-fit: cover;
    border-radius: 6px;
}
.photos-box-wrapper .imagelistbox ul{
    gap: 20px!important;
}
.photos-box-wrapper .imagelistbox ul li{
    padding: 0;
}
.photos-box-wrapper .imagelistbox ul li img{
    width: 100%;
    height: 230.431px;
    object-fit: cover;
    border-radius: 6px;
}

@media screen and (min-width:2200px) {
  .photos-box-wrapper .main-photo {
    height: 800px;
   }
}

@media screen and (max-width:1500px){
    .search-suggestion-wrapper{    
        padding: 0;
    }   
}

@media screen and (max-width:1400px){
    .related-wrapper .related-content img {
        margin-left: 15px;
        min-width: 90px;
    }
    .feeds {
        padding: 25px;
    }
    .highlights {
        padding: 25px;
    }
    .newAi {
        padding: 25px;
    }
    .feeds .podcast-tabs button{
        font-size: 14px;
    }
}
  
@media screen and (max-width: 1200px) {
    .latest-news .grid-column {
        flex: 0 0 calc(25% - 20px);
        max-width: calc(25% - 20px);
    }
    .latest-news .row{
        margin: 0;
        gap:20px;
    }
    .latest-news .grid-column{
        padding: 0;
    }
    .related-wrapper .related-content img {
        min-width: 138px;
    }
}

@media screen and (max-width: 1199px) {
    .mobile-input-wrapper{
        display: block;
    }
    .mobile-input-wrapper .follow-up-wrapper{
        margin: 15px 0 5px;
    }
    .desktop-input-wrapper{
        display: none;
    }
    .result-wrapper .result-box{
        margin-bottom: 0;
    }
    .scrollDivRef.scroll-container {
        padding-right: 0 !important;
        max-height: 100%;
    }
    .photos-box-wrapper .main-photo {
        height: auto;
    }
    .photos-box-wrapper .imagelistbox ul li img{
        height: 150px;
    }
}

@media screen and (max-width: 992px) {
    .latest-news .grid-column {
        flex: 0 0 calc(33.33% - 20px); 
        max-width: calc(33.33% - 20px);
    }
}

@media screen and (max-width: 768px) {
    .newAi { 
        margin: 35px 18px 20px;
        padding: 40px 50px;
    }
    .highlights{
        margin: 20px 18px;
    }
    .feeds{
        margin: 20px 18px;
        padding: 40px 50px;
    }
    .latest-news .grid-column {
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
    }
    .header .logo-wrapper img {
        max-width: 120px;
    }
    .bannerContent h1 {
        font-size: 22px;
        line-height: normal;
    }
    .bannerContent p {
        font-size: 16px;
        line-height: inherit;
    }
    .imageWrapper {
        height: 380px;
    }
    .carousel-wrapper .bannerContent{
        padding: 0 15px
    }
    .search-suggestion-wrapper .input-wrapper{
        padding: 0 15px 0 15px;
        margin-top: 30px;
    }
    .search-suggestion-wrapper .suggestion-wrapper{
        padding: 0px 15px!important;
    }
    div.latest-news {
        padding: 15px;
        margin: 35px 15px 0;
    }
    .latest-news h5,.highlights h5,.feeds h5, .newAi h5{
        font-size: 22px;
    }
    .latest-news .created-wrapper{
        margin-top: 20px;
    }
    .footer-wrapper{
        padding: 40px 15px 0;
    }
    .footer-wrapper .social-media li{
        width: 48%;
        height: 50px;
    }   
    .footer-wrapper .social-media{
        padding: 0 0 25px;
        gap: 19px;
    }  
    .footer-wrapper form .form-group, .footer-wrapper form .form-group input{
        width: 100%;
    }
    .result-wrapper {
        padding: 80px 15px 20px;
    }
    .result-wrapper div.side-section{
       padding-top: 20px;
    }
    .mobile-input-wrapper{
        display: block;
    }
    .mobile-input-wrapper .follow-up-wrapper{
        margin: 15px 0 5px;
    }
    .desktop-input-wrapper{
        display: none;
    }
    .video-wrapper,.sources-wrapper{
        margin-bottom: 30px;
    }
    .result-wrapper .result-box{
        margin: 0;
    }
}

@media screen and (max-width:767px){
    .header-top-wrapper{
        padding-top: 68px;
    }
    .MuiPagination-ul {
        -webkit-box-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }
    .newAi { 
        margin: 35px 15px 20px;
        padding: 15px;
    }
    .highlights {
        margin: 0 15px;
        padding: 15px;
    }
    .feeds {
       margin: 20px 15px;
       padding: 16px;
    }
    .newAi .category {
        padding: 5px 8px;
        font-size: 12px;
    }
    .newAi .imageText {
        position: absolute;
        bottom: 10%;
        opacity: 0.7;
        background: #080808;
        margin: 0 20px;
        padding: 10px 20px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
    }
    .feeds .feed-image {
        width: 100%;
        height: 156px;
        object-fit: cover;
        border-radius: 5px;

    } 
    .highlight-card img {
        width: 100%;
        height: 156px;
        object-fit: cover;
        border-radius: 5px;
    } 
    .articleImage {
        margin-right: 0;
        height: auto;
    } 
    .highlight-card {
        display: flex;
        flex-direction: column;
    }  
    .podcast-card {
        display: flex;
        flex-direction: column;
    }  
    .highlight-card .highlight-content{
        padding: 15px 0 0;
    }
    .pagination-nav.sx-pagination{
        position: static;
    }
    .pagination-nav.sx-pagination .paginations{
        margin: 0 0 30px;
    }
    .feeds .podcast-tabs button{
        font-size: 16px;
        padding: 16px 0;
    }
    .result-wrapper {
        padding: 80px 15px 20px;
    }
    .sub-title, h5.sub-title,.result-wrapper .result-title {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .sources-wrapper .box-wrapper h6,.related-wrapper .content-wrapper a {
        font-size: 16px;
    }
    .related-wrapper .related-content img{
        margin-left: 10px;
    }
    .footer-wrapper form{
        padding: 20px 0!important;
    }
    .footer-wrapper form .fd-form-content{
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .latest-news .grid-column {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100%);
        max-width: calc(100%);
        margin-bottom: 20px;
    }
    .footer-wrapper form button{
        width: 100%;
    }
    .footer-wrapper .social-media li{
        width: 100%;
    }
    .photos-box-wrapper .imagelistbox ul li img {
        height: 80px;
    }
}

@media screen and (max-width:899px){

    .header .hamburger-icon{
        border: 1px solid #eee;
        margin-right: 10px;
     
     }
    .header .logo-wrapper img {
        max-width: 120px;
        margin-left: 10px;
    }
    .menus-wrapper.open{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 58px;
        z-index: 4;
        background: white;
        width: 100%;
        padding-top: 10px;
        box-shadow: 0px 2px 9px -3px rgb(0 0 0 / 30%);
    }
    .header .menus-wrapper.open a.links {
        padding: 12px 20px;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #eee;
        border-radius: 0;
    }
    .header .menus-wrapper.open .social-links-wrapper{
        margin: 0;
        padding: 15px 0;
    }
    header.header {
        padding: 8px 0px;
        width: 100%;
    }    
    .bannerContent h1,.bannerContent p{
        width: 100%;
    }
}
